import { jsx as _jsx } from "react/jsx-runtime";
import { createRoot } from 'react-dom/client';
import '../styles/main.scss';
import Header from './header';
import Home from './homePage';
const headerElement = document.getElementById('header');
if (headerElement != null) {
    const rootHeader = createRoot(headerElement);
    rootHeader.render(_jsx(Header, {}));
}
const homeElement = document.getElementById('homePage');
if (homeElement != null) {
    const rootHome = createRoot(homeElement);
    rootHome.render(_jsx(Home, {}));
}
