import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// Header.js
import { useEffect, useState } from 'react';
import styles from '../styles/Home.module.scss';
import Logo from './logo';
import Footer from './footer';
const Header = () => {
    const headerLinksJson = document.getElementById('headerLinksJson');
    const isClubPage = window.location.pathname.includes('/clubs');
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsScrolled(scrollTop > 0);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const [isMenuOpen, setMenuOpen] = useState(false);
    const toggleClassname = isMenuOpen
        ? `${styles.header} ${styles.menuNavigationOpen}`
        : styles.header;
    const [headersLinks, setHeadersLinks] = useState([]);
    useEffect(() => {
        if (headerLinksJson === null || headerLinksJson === void 0 ? void 0 : headerLinksJson.value) {
            const headerLinks = JSON.parse(headerLinksJson.value);
            setHeadersLinks(headerLinks);
        }
    }, []);
    return (_jsx("header", { className: `${toggleClassname}  ${(isScrolled || isMenuOpen) ? styles.headerBlue : ''} ${isClubPage ? styles.headerBlack : ''}`, children: _jsxs("div", { className: styles.mainContainer, children: [_jsx("div", { className: styles.headerLogo, children: _jsx(Logo, {}) }), _jsx("button", { className: styles.menuButton, onClick: () => setMenuOpen(!isMenuOpen), children: isMenuOpen
                        ? _jsxs(_Fragment, { children: [_jsx("span", { className: styles.menuClose, children: _jsx("svg", { className: styles.spriteIcon, children: _jsx("use", { xlinkHref: '#i-close' }) }) }), "Close"] })
                        : _jsxs(_Fragment, { children: [_jsxs("span", { className: styles.menuBurger, children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] }), "Menu"] }) }), _jsxs("div", { className: styles.headerLinks, children: [_jsx("div", { className: styles.headerLinksNav, children: headersLinks.map((headerLink, index) => (_jsx("div", { className: styles.headerLinkContainer, children: _jsx("a", { href: headerLink.Url, className: `
                      ${styles.button}
                      ${styles['button--link']}
                    `, target: headerLink.Target ? '_blank' : '_self', rel: 'noreferrer', children: headerLink.Name }) }, index))) }), !isMenuOpen &&
                            _jsx("div", { className: styles.headerLinksFooter, children: _jsx(Footer, { headerFooter: true }) })] })] }) }));
};
export default Header;
