import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styles from '../styles/Home.module.scss';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import parse from 'html-react-parser';
export default function Testimonials() {
    let testimonial = document.getElementById('testimonial');
    if (testimonial.value) {
        testimonial = JSON.parse(testimonial.value);
    }
    return (_jsxs(_Fragment, { children: [_jsx("style", { children: `
        .swiper-testimonials {
  height: unset !important;

  .swiper-button-prev,
  .swiper-button-next {
    width: 7.2rem;
    height: 6.3rem;
    backdrop-filter: blur(5px);
    border-radius: 6.6rem;
    transition: all .35s ease;
    border: 4px solid #FFF7E5;

    @media (max-width: 774px) {
      position: relative !important;
      height: 4rem;
      width: 4.5rem;
      
      &:after {
        background-size: 12px !important;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 2.5rem;
      height: 2.5rem;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyMyAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIyLjAwOTkgMTMuMDYwN0MyMi41OTU3IDEyLjQ3NDkgMjIuNTk1NyAxMS41MjUxIDIyLjAwOTkgMTAuOTM5M0wxMi40NjM5IDEuMzkzNEMxMS44NzgyIDAuODA3NjEzIDEwLjkyODQgMC44MDc2MTMgMTAuMzQyNiAxLjM5MzRDOS43NTY4MyAxLjk3OTE5IDkuNzU2ODMgMi45Mjg5MyAxMC4zNDI2IDMuNTE0NzJMMTguODI3OSAxMkwxMC4zNDI2IDIwLjQ4NTNDOS43NTY4MyAyMS4wNzExIDkuNzU2ODMgMjIuMDIwOCAxMC4zNDI2IDIyLjYwNjZDMTAuOTI4NCAyMy4xOTI0IDExLjg3ODEgMjMuMTkyNCAxMi40NjM5IDIyLjYwNjZMMjIuMDA5OSAxMy4wNjA3Wk0wLjk0OTIxOSAxMy41TDIwLjk0OTIgMTMuNUwyMC45NDkyIDEwLjVMMC45NDkyMTkgMTAuNUwwLjk0OTIxOSAxMy41WiIgZmlsbD0iI0ZGRjdFNSIvPgo8L3N2Zz4K");
      background-position: center;
      background-size: 25px 25px;
      background-repeat: no-repeat;
    }

    &.swiper-button-disabled {
      opacity: 0;
      visibility: hidden;
    }


  }

  .swiper-button-prev {
    &:after {
      transform: rotate(180deg);
    }
  }

  >.swiper-wrapper {
    .swiper-slide:not(.swiper-slide-active) {
      opacity: 0 !important;
    }
  }
}
      ` }), _jsxs(Swiper, { className: `${styles.testimonialsCarousel} swiper-testimonials`, modules: [Navigation, Autoplay], spaceBetween: 40, loop: true, autoHeight: false, slidesPerView: 1, centeredSlides: true, scrollbar: { draggable: false }, grabCursor: true, autoplay: {
                    pauseOnMouseEnter: true,
                    disableOnInteraction: true,
                    delay: 600000
                }, navigation: {
                    enabled: true,
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }, children: [testimonial.map((item) => {
                        return (_jsx(SwiperSlide, { children: _jsx("blockquote", { className: styles.blockquoteContainer, children: _jsxs("div", { className: styles.blockquote, children: [_jsx("span", { className: styles.blockquoteText, children: parse(item.TestimonialContent) }), _jsxs("span", { className: styles.blockquoteName, children: [item.TestimonialName, ", ", item.TestimonialTitle] })] }) }) }, item.TestimonialName));
                    }), _jsxs("div", { className: styles['testimonialsCarousel--navigation'], children: [_jsx("div", { className: 'swiper-button-prev' }), _jsx("div", { className: 'swiper-button-next' })] })] })] }));
}
