import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useEffect, useState } from 'react';
const VideoPlayer = ({ src, poster, type }) => {
    const videoRef = useRef(null); // Reference to the video element
    const [play, setPlay] = useState(false); // State to manage play status
    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement)
            return;
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                setPlay(entry.isIntersecting); // Update play state based on visibility
            });
        }, {
            root: null, // observing for viewport
            rootMargin: '0px',
            threshold: 0.1
        });
        observer.observe(videoElement);
        return () => {
            observer.unobserve(videoElement); // Cleanup the observer when component unmounts
        };
    }, []); // Empty dependency array ensures the effect runs only once after initial render
    useEffect(() => {
        const videoElement = videoRef.current;
        if (!videoElement)
            return;
        if (play) {
            videoElement.play().catch((error) => console.error("Error playing video", error));
        }
        else {
            videoElement.pause();
        }
    }, [play]); // This effect runs when 'play' state changes
    return (_jsx("video", { crossOrigin: 'anonymous', preload: 'none', ref: videoRef, loop: true, muted: true, playsInline: true, poster: poster || '', children: _jsx("source", { src: src, type: type }) }));
};
export default VideoPlayer;
