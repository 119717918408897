export const isMobileOrIpadService = () => {
    // Define a breakpoint for mobile and iPad devices
    const mobileOrIpadBreakpoint = 768; // Adjust as needed
    return window.innerWidth <= mobileOrIpadBreakpoint;
};
export const isAppleDevice = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const platform = window.navigator.platform.toLowerCase();
    // Check if it's an iOS device
    if (/iphone|ipod|ipad/.test(userAgent) || (/macintosh/.test(userAgent) && 'ontouchend' in document)) {
        return true;
    }
    // Check if it's a MacBook and the browser is Safari
    const isMacBook = /macintosh/.test(userAgent) && platform === 'macintel';
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isMacBook && isSafari) {
        return true;
    }
    // Check if it's an iPad
    if (/ipad/.test(userAgent) || (platform === 'macintel' && navigator.maxTouchPoints > 1)) {
        return true;
    }
    // If none of the conditions are met
    return false;
};
