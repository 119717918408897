import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from '../styles/Home.module.scss';
export default function ContactCTA() {
    return (_jsx("section", { className: `
        ${styles.sectionGetInTouch}
        ${styles['section--blue']}
        ${styles['section--center']}
        ${styles.cta}
        ${styles.contactCta}
    `, children: _jsx("div", { className: styles.contactCtaContainer, children: _jsxs("div", { className: styles.mainContainer, children: [_jsx("span", { className: styles.ctaTitleSmall, children: "Need Help" }), _jsx("h2", { className: styles.ctaTitle, children: "If you have questions, we\u2019re here to help" }), _jsx("div", { className: styles.ctaContent, children: _jsx("a", { href: '/contactus', className: `${styles.buttonPrimary} ${styles.buttonPrimaryMedium}`, children: "GET IN TOUCH" }) })] }) }) }));
}
