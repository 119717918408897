import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { isAppleDevice } from '../../ClientApp/services/ResponsiveService';
import styles from '../styles/Home.module.scss';
import VideoPlayer from './videoPlayer';
export default function Mareuee({ content, isRed, isReverse }) {
    return (_jsx("div", { className: `${styles.marqueeContainer}`, children: _jsx("div", { className: `${styles.marquee} ${isRed ? styles.mareueeRed : ''}`, children: _jsx("div", { className: `${styles.track} ${isReverse ? styles.trackReverse : ''}`, children: [...Array(20).keys()].map((x, index) => {
                    return (_jsxs("div", { children: [_jsx("span", { children: content }), _jsx("div", { children: _jsx(VideoPlayer, { src: isRed
                                        ? isAppleDevice()
                                            ? '/resources/videos/hevc/arl-ball-out-red-hevc.mov'
                                            : '/resources/videos/webm/arl-ball-out-red.webm'
                                        : isAppleDevice()
                                            ? '/resources/videos/hevc/arl-ball-landing-hevc.mov'
                                            : '/resources/videos/webm/arl-ball-landing.webm', poster: isRed
                                        ? '/resources/images/new/background/arl-ball-out-red.png'
                                        : '/resources/images/new/background/arl-ball-landing.png', type: isAppleDevice() ? 'video/quicktime' : 'video/webm' }) })] }, content + x));
                }) }) }) }));
}
