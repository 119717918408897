import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useEffect, useRef } from 'react';
const useLazyLoad = (lazy) => {
    const ref = useRef(null);
    const [isVisible, setIsVisible] = useState(lazy ? false : true); // Default to true if not lazy
    useEffect(() => {
        if (!lazy) {
            return; // If not lazy, do nothing.
        }
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setIsVisible(true);
                observer.disconnect();
            }
        }, { threshold: 0.01 });
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, [lazy]); // Depend on the lazy prop to re-initialize if it changes
    return [ref, isVisible];
};
const LazyImage = ({ src, alt, className, lazy = true }) => {
    const [imgRef, isVisible] = useLazyLoad(lazy);
    const [imageLoaded, setImageLoaded] = useState(false);
    return (_jsx("img", { className: className, ref: imgRef, src: isVisible ? src : '', alt: alt, loading: lazy ? 'lazy' : 'eager', style: {
            filter: isVisible && !imageLoaded ? 'blur(8px)' : 'none',
            opacity: !imageLoaded ? 0 : 1
        }, onLoad: () => setImageLoaded(true), onError: () => setImageLoaded(false) }));
};
export default LazyImage;
